/** @jsx jsx */

import { Fragment, memo } from "react";
import { jsx } from "theme-ui";

import { AnimalType } from "../../templates/Animal/Header";
import AnimalCovers from "../AnimalCovers";
import Container from "../Container";
import MainMenu from "../MainMenu";

const Homepage: React.FC<{
  theme?: AnimalType;
}> = ({ theme }) => {
  let animalTypes: AnimalType[] = ["aquatic", "subaquatic", "terrestrial"];

  switch (theme) {
    case "terrestrial":
      animalTypes = ["terrestrial"];
      break;
    case "aquatic":
    case "subaquatic":
      animalTypes = ["aquatic", "subaquatic"];
      break;
  }

  return (
    <Fragment>
      <div
        sx={{
          left: 0,
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1,
        }}
      >
        <Container>
          <MainMenu theme={theme} />
        </Container>
      </div>
      <AnimalCovers types={animalTypes} />
    </Fragment>
  );
};

export default memo(Homepage);

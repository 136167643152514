/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsx jsx */
import "flickity/css/flickity.css";

import { Link } from "gatsby";
import { memo } from "react";
import Flickity from "react-flickity-component";
import { jsx, Styled } from "theme-ui";

import useWindowSize from "../../helpers/useWindowSize";
import AnimalCover from "./AnimalCover";
import { useQuery } from "./useQuery";
export type AnimalType = "terrestrial" | "subaquatic" | "aquatic";

const AnimalCovers: React.FC<{ types?: AnimalType[] }> = ({
  types = ["aquatic", "subaquatic", "terrestrial"],
}) => {
  const data = useQuery();

  const { height } = useWindowSize();

  if (!height) {
    return null;
  }

  return (
    <div
      sx={{
        display: "block",
        height: "100%",
        left: 0,
        overflowX: "hidden",
        overflowY: "hidden",
        position: "absolute",
        top: 0,
        width: "100%",
      }}
    >
      <Flickity
        options={{
          wrapAround: true,
          pageDots: false,
        }}
        reloadOnUpdate
        static
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {data.allMarkdownRemark.nodes
          .filter((node) =>
            types.includes(node.frontmatter?.type as AnimalType)
          )
          .filter((node) => {
            if (process.env.NODE_ENV === "production") {
              return node.frontmatter?.online === true;
            }
            return true;
          })
          .map((node, index) => {
            const { type, header, url, name: animalName, hook } =
              node.frontmatter || {};
            const { animal, background, name } = header || {};

            return (
              <div
                key={index}
                sx={{
                  width: ["70%", "60%"],
                  height,
                }}
              >
                <Styled.a
                  as={Link}
                  // @ts-ignore
                  to={url ? `/${url}` : "/"}
                  sx={{ display: "block", height: "100%", border: "none" }}
                >
                  <AnimalCover
                    animal={animal?.childImageSharp?.fluid}
                    animalName={animalName}
                    background={background?.childImageSharp?.fluid}
                    content={hook}
                    name={name?.childImageSharp?.fluid}
                    type={type}
                  />
                </Styled.a>
              </div>
            );
          })}
      </Flickity>
    </div>
  );
};

export default memo(AnimalCovers);

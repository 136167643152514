/** @jsx jsx */
import { memo } from "react";
import { jsx } from "theme-ui";

import AnimalHeader from "../../AnimalHeader";
import AnimalHeaderSubaquatic from "../../AnimalHeaderSubaquatic";

export type AnimalType = "terrestrial" | "subaquatic" | "aquatic";

interface Picture {
  base64?: string | null;
  aspectRatio?: number | null;
  src?: string | null;
  srcSet?: string | null;
  sizes?: string | null;
}

const AnimalCover: React.FC<{
  animal?: Picture | null;
  animalName?: string | null;
  background?: Picture | null;
  content?: string | null;
  name?: Picture | null;
  type?: string | null;
}> = ({ animalName, type, animal, content, background, name }) => {
  let element: React.ReactElement;

  switch (type) {
    case "terrestrial":
    case "aquatic":
    default:
      element = (
        <AnimalHeader
          animal={animal}
          animalName={animalName}
          background={background}
          name={name}
          noFx={true}
        />
      );
      break;
    case "subaquatic":
      element = (
        <AnimalHeaderSubaquatic
          animal={animal}
          animalName={animalName}
          background={background}
          name={name}
          noFx={true}
        />
      );
      break;
  }

  return (
    <div sx={{ position: "relative", height: "100%" }}>
      {element}
      <div
        sx={{
          bottom: 0,
          boxSizing: "border-box",
          color: "rgba(255,255,255,.5)",
          display: ["none", "none", "none", "block"],
          fontSize: 1,
          left: 0,
          paddingBottom: [0, 0, 5],
          paddingLeft: [0, 0, "20%", "10%"],
          position: "absolute",
          paddingRight: [0, 0, "20%", "10%"],
          textAlign: "center",
          width: "100%",
          "::before": {
            backgroundColor: "rgba(255,255,255,.5)",
            content: '""',
            display: ["none", "none", "block"],
            ml: "auto",
            marginRight: "auto",
            marginBottom: 4,
            height: (theme): number => theme.borderWidths[1],
            width: "6rem",
          },
        }}
      >
        {content}
      </div>
    </div>
  );
};

export default memo(AnimalCover);

import { graphql, useStaticQuery } from "gatsby";

import { AnimalCoversQuery } from "../../../__generated__/queries";

export const useQuery = (): AnimalCoversQuery => {
  return useStaticQuery<AnimalCoversQuery>(graphql`
    query AnimalCoversQuery {
      allMarkdownRemark(
        filter: { fields: { sourceName: { eq: "animal-pages" } } }
        sort: { fields: [frontmatter___order, frontmatter___name], order: ASC }
      ) {
        nodes {
          frontmatter {
            name
            online
            url
            type
            hook
            header {
              name {
                childImageSharp {
                  fluid(quality: 10, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              background {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              animal {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
};
